import { Dispatch, FC } from "react";

import { Button } from "@atlas-ui/components";

import Image from "next/image";

import InviteSentIllustration from "../../../assets/atlas-invitation-sent.svg";
import { Action, State } from "./invite-dialog";

export interface InviteDialogSentModeProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const InviteDialogSentMode: FC<InviteDialogSentModeProps> = ({
  state,
  dispatch,
}) => (
  <div className="flex flex-col gap-4">
    <h3 className="text-lg font-semibold text-foreground">Invite sent!</h3>
    <div className="flex flex-col justify-center gap-4 items-center">
      <Image
        src={InviteSentIllustration}
        alt="invite-sent-illustration"
        className="w-[280px]"
        width={280}
        height={280}
      />
      <div className="flex justify-center min-h-[50px] gap-1 text-foreground">
        <span> Your invite has been sent to </span>{" "}
        <a className="text-primary" href={`mailto:${state.email}`}>
          {state.email}
        </a>
      </div>
    </div>
    <div className="flex justify-end items-center">
      <Button
        onClick={() => {
          dispatch({
            type: "SET_MODE",
            mode: "default",
          });

          dispatch({
            type: "SET_EMAIL",
            email: "",
          });
        }}
      >
        Finish
      </Button>
    </div>
  </div>
);

import { Dispatch, FC, useContext, useState } from "react";

import {
  AlertDialog,
  AlertDialogContent,
  Avatar,
  Button,
  ErrorContext,
} from "@atlas-ui/components";
import { removeUser } from "@atlas-ui/services";
import { User } from "@atlas-ui/types";
import { UserProfile } from "@auth0/nextjs-auth0/client";

import { LoadingButton } from "@/components/common/loading-button";

import mixpanel from "mixpanel-browser";

import { Action } from "../common/dialogs/invite-dialog";
import { getAvatarName } from "../layout/organization-members-section";

export const colors = [
  "bg-blue-300",
  "bg-popover-foreground",
  "bg-atlas-golden",
];

export interface MemberListItemProps {
  member: User;
  user: UserProfile;
  index: number;
  canDelete?: boolean;
  onRemove: () => void;
  dispatch: Dispatch<Action>;
}

export const MemberListItem: FC<MemberListItemProps> = ({
  member,
  user,
  index,
  canDelete = true,
  dispatch,
  onRemove,
}) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { setError } = useContext(ErrorContext);

  const onRemoveUser = async () => {
    try {
      setIsRemoving(true);
      await removeUser(member.id);
      dispatch({
        type: "SET_MODE",
        mode: "remove",
      });
      dispatch({
        type: "SET_REMOVED_USER",
        removedUser: member,
      });
      onRemove();
    } catch (error) {
      setError({
        title: "Error",
        message: (error as Error)?.message || "Error removing user",
      });
    } finally {
      setIsRemoving(false);
      setShowRemoveConfirmation(false);
    }
  };

  return (
    <div
      className="flex items-center gap-2"
      data-testid={`member-${member.id}`}
    >
      <AlertDialog open={showRemoveConfirmation}>
        <AlertDialogContent className="p-6 flex flex-col gap-4">
          <div className="flex flex-col gap-2 h-[154px]">
            <h3 className="text-lg font-semibold">
              Remove{" "}
              <span className="text-primary">
                {member.firstName} {member.lastName}
              </span>{" "}
              from this organization?
            </h3>
            <span className="text-base text-muted-foreground">
              Any document uploaded by this user will still be accessible inside{" "}
              {member.companyName} and should be removed manually.
            </span>
            <span className="text-base front-semibold text-muted-foreground">
              You will only revoke access and credentials from this user.
            </span>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                mixpanel.track("action-intention-remove-user");
                setShowRemoveConfirmation(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="destructive"
              onClick={onRemoveUser}
              isLoading={isRemoving}
            >
              Yes, remove user
            </LoadingButton>
          </div>
        </AlertDialogContent>
      </AlertDialog>
      <Avatar
        size="sm"
        fallbackClassName={`${
          colors[index % colors.length]
        } text-white text-[10px] cursor-pointer`}
        fallback={getAvatarName(
          `${member.firstName ?? member.email} ${member.lastName ?? ""}`
        )}
      />
      <div
        data-testid={`member-${member.id}`}
        className="text-sm flex flex-1 justify-between"
      >
        <div className="flex gap-2 flex-1">
          <span className="text-muted-foreground">{member.email}</span>
          {user?.sub === member.authId && (
            <span className="text-foreground">(You)</span>
          )}
        </div>
        <div>
          {canDelete && !member.onboardingComplete && (
            <button
              className="text-xs text-muted-foreground font-small mr-2"
              disabled
              data-testid="pending-member"
            >
              (Pending)
            </button>
          )}
          {canDelete && (
            <button
              className="text-xs text-primary font-medium"
              onClick={() => {
                mixpanel.track("action-intention-remove-user");
                setShowRemoveConfirmation(true);
              }}
              data-testid="remove-member"
              tabIndex={0}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

import { FC, useMemo, useRef, useState } from "react";

import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@atlas-ui/components";
import { UserProfile } from "@auth0/nextjs-auth0/client";
import {
  ArrowLeftStartOnRectangleIcon,
  DocumentCheckIcon,
  EnvelopeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

import {
  PARTIES_LOCALSTORAGE_KEY,
  TAB_LOCALSTORAGE_KEY,
  TAGS_LOCALSTORAGE_KEY,
} from "@/app/my-documents/lib/constants";
import { LogoutMenuItem } from "@/components/layout/logout-menu-item";
import { MenuItem } from "@/components/layout/menu-item";

import { useOnClickOutside } from "usehooks-ts";

interface UserDropdownMenuProps {
  user: UserProfile;
}

const localStorageKeys = [
  TAB_LOCALSTORAGE_KEY,
  PARTIES_LOCALSTORAGE_KEY,
  TAGS_LOCALSTORAGE_KEY,
  PARTIES_LOCALSTORAGE_KEY,
];

const clearLocalStorageKeys = () => {
  localStorageKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const UserDropdownMenu: FC<UserDropdownMenuProps> = ({ user }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onLogout = () => {
    clearLocalStorageKeys();
  };

  const userAvatarName = useMemo(
    () =>
      user?.name
        ?.split(" ")
        .map((name) => name[0])
        .join(""),
    [user]
  );

  const onOptionClicked = () => setIsOpen(false);

  useOnClickOutside(containerRef, onOptionClicked);

  return (
    <DropdownMenu open={isOpen}>
      <DropdownMenuTrigger onClick={() => setIsOpen(!isOpen)} asChild>
        <Avatar
          size="md"
          className="cursor-pointer"
          fallback={userAvatarName}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        ref={containerRef}
        side="bottom"
        className="mr-6 mt-2 p-0 w-[240px]"
      >
        <div className="flex flex-col px-3 py-2 border-b border-b-border">
          {user.name && (
            <span className="text-foreground text-sm font-semibold">
              {user.name}
            </span>
          )}
          {user.email && (
            <span className="text-xs text-muted-foreground">{user.email}</span>
          )}
        </div>
        <div className="flex flex-col">
          <MenuItem
            href="mailto:docsnap@nimble.la"
            onClick={onOptionClicked}
            icon={<EnvelopeIcon className="text-muted-foreground w-4 h-4" />}
            text="Contact Us"
          />
          <MenuItem
            href="/legal?tab=terms-of-service"
            onClick={onOptionClicked}
            icon={
              <DocumentCheckIcon className="text-muted-foreground w-4 h-4" />
            }
            text="Terms of Service"
          />
          <MenuItem
            href="/legal?tab=privacy-policy"
            onClick={onOptionClicked}
            icon={<LockClosedIcon className="text-muted-foreground w-4 h-4" />}
            text="Privacy Policy"
          />
          <LogoutMenuItem
            href="/api/auth/logout"
            className={"border-t border-t-border"}
            icon={
              <ArrowLeftStartOnRectangleIcon className="text-muted-foreground w-4 h-4" />
            }
            text="Log Out"
            onClick={() => {
              onOptionClicked();
              onLogout();
            }}
            testId="logout-button"
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { FC } from "react";

import { MenuItemProps } from "@/components/layout/menu-item";
import { cn } from "@/lib/utils";

export const LogoutMenuItem: FC<MenuItemProps> = ({
  href,
  icon,
  text,
  onClick,
  testId,
  className,
}) => (
  <a
    href={href}
    className={cn(
      "hover:bg-accent px-[8px] py-1.5 flex items-center gap-[8px] pr-4",
      className
    )}
    onClick={onClick}
    data-testid={testId}
  >
    {icon}
    <span className="text-sm">{text}</span>
  </a>
);

import { FC } from "react";

import { User } from "@atlas-ui/types";
import { useUser } from "@auth0/nextjs-auth0/client";

import { MemberAvatar } from "./member-avatar";

interface OrganizationMembersProps {
  members?: User[];
}

export const colors = [
  "bg-blue-300",
  "bg-popover-foreground",
  "bg-atlas-golden",
];

export const getAvatarName = (name: string) => {
  if (!name) return "";
  const [firstName, lastName] = name.split(" ");
  return `${firstName?.[0]?.toUpperCase() || ""}${
    lastName?.[0]?.toUpperCase() || ""
  }`;
};

export const OrganizationMembersSection: FC<OrganizationMembersProps> = ({
  members = [],
}) => {
  const { user } = useUser();
  const filteredMembers = members
    .filter((member) => member.authId !== user?.sub)
    .slice(0, 4);

  return (
    <div
      className="flex items-center gap-1 flex-1"
      data-testid="organization-members"
    >
      <div className="flex items-center mr-2">
        {filteredMembers.map((member, index) => (
          <MemberAvatar key={member.id} member={member} index={index} />
        ))}
      </div>
      {members.length > 5 && (
        <div>
          <span className="text-muted-foreground text-sm">
            +{members.length - 4}
          </span>
        </div>
      )}
    </div>
  );
};

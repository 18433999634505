import { FC } from "react";

import {
  Avatar,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@atlas-ui/components";
import { User } from "@atlas-ui/types";

import { colors, getAvatarName } from "./organization-members-section";

export const MemberAvatar: FC<{ member: User; index: number }> = ({
  member,
  index,
}) => {
  const avatarName = getAvatarName(
    `${member.firstName ?? member.email} ${member.lastName ?? ""}`
  );
  return (
    <TooltipProvider key={`member-${member.id}`}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Avatar
            className="-mr-2"
            size="sm"
            fallbackClassName={`${
              colors[index % colors.length]
            } text-white text-[10px] cursor-pointer`}
            fallback={avatarName}
          />
        </TooltipTrigger>
        <TooltipContent>
          {member.firstName ?? member.email} {member.lastName ?? ""}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import { Dispatch, FC } from "react";

import { Button } from "@atlas-ui/components";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { Action, State } from "./invite-dialog";

export interface InviteDialogRemoveModeProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const InviteDialogRemoveMode: FC<InviteDialogRemoveModeProps> = ({
  dispatch,
  state,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="py-10 flex-col flex gap-2.5 justify-center items-center">
        <CheckCircleIcon className="w-6 h-6 fill-primary" />
        <span className="text-base font-medium text-foreground text-center">
          <span className="text-primary">{state.removedUser?.email}</span> was
          removed from {state.removedUser?.organizations?.[0]?.name}.
        </span>
        <span className="text-sm font-normal text-foreground">
          This user will receive an notification through email.
        </span>
      </div>
      <div className="flex justify-end items-center">
        <Button
          onClick={() => {
            dispatch({
              type: "SET_MODE",
              mode: "default",
            });
          }}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

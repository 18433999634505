import { Dispatch, FC, useCallback, useContext, useState } from "react";

import { ErrorContext, Input, Separator } from "@atlas-ui/components";
import { inviteUser } from "@atlas-ui/services";
import { User } from "@atlas-ui/types";
import { UserProfile } from "@auth0/nextjs-auth0/client";

import { LoadingButton } from "@/components/common/loading-button";

import { isEmpty, noop } from "lodash";
import mixpanel from "mixpanel-browser";
import Link from "next/link";

import { MemberListItem } from "../../layout/member-list-item";
import { Action, State } from "./invite-dialog";

export interface InviteDialogDefaultModeProps {
  state: State;
  isOwner?: boolean;
  membersToBeDisplayed?: User[];
  user: UserProfile;
  dispatch: Dispatch<Action>;
  onMemberRemoved?: (member: User) => void;
  onMemberInvited?: () => void;
}

export const InviteDialogDefaultMode: FC<InviteDialogDefaultModeProps> = ({
  state,
  isOwner,
  membersToBeDisplayed,
  user,
  dispatch,
  onMemberRemoved,
  onMemberInvited,
}) => {
  const { setError } = useContext(ErrorContext);
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const selfMember = membersToBeDisplayed?.find(
    (member) => member.authId === user?.sub
  );
  const otherMembers = membersToBeDisplayed?.filter(
    (member) => member.authId !== user?.sub
  );

  const sendInvitation = useCallback(async () => {
    if (!state.email || isEmpty(state.email)) {
      return;
    }

    setIsInviting(true);

    try {
      await inviteUser(state.email);
      dispatch({
        type: "SET_MODE",
        mode: "sent",
      });

      mixpanel.track("action-success-invite");
      onMemberInvited?.();
    } catch (error) {
      const errorMessage = (error as Error)?.message || "Error inviting user";
      setError({
        title: (error as Error)?.name || "Error",
        message: (
          <>
            {errorMessage}
            <br />
            <span>
              Contact{" "}
              <Link className="text-primary" href="mailto:atlas-beta@nimble.la">
                support
              </Link>{" "}
              for more information.
            </span>
          </>
        ),
      });
    } finally {
      setIsInviting(false);
    }
  }, [dispatch, onMemberInvited, setError, state.email]);

  const onRemoveUserIntent = (member: User) => {
    onMemberRemoved?.(member);
  };

  return (
    <>
      <div className="flex gap-2 pt-2">
        <Input
          containerClassName="flex-1"
          type="email"
          placeholder="Add user email"
          onChange={(e) => {
            dispatch({
              type: "SET_EMAIL",
              email: e.target.value,
            });
          }}
          value={state.email}
        />
        <LoadingButton
          onClick={sendInvitation}
          className="min-w-[117px]"
          isLoading={isInviting}
        >
          Send Invite
        </LoadingButton>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col gap-4">
        <span className="text-sm font-medium text-foreground">
          Contributors list
        </span>
        <div className="flex flex-col gap-2 max-h-[300px] overflow-y-auto">
          {selfMember && (
            <MemberListItem
              index={0}
              key={`modal-invite-${selfMember.id}`}
              user={user}
              member={selfMember}
              canDelete={false}
              dispatch={dispatch}
              onRemove={noop}
            />
          )}
          {otherMembers?.map((member, idx) => (
            <MemberListItem
              index={idx + 1}
              key={`modal-invite-${member.id}`}
              user={user}
              member={member}
              canDelete={isOwner}
              dispatch={dispatch}
              onRemove={() => {
                onRemoveUserIntent(member);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

import { FC, useReducer } from "react";

import { BaseDialog, LoadingScreen } from "@atlas-ui/components";
import { User } from "@atlas-ui/types";
import { useUser } from "@auth0/nextjs-auth0/client";

import { InviteDialogDefaultMode } from "./invite-dialog-default-mode";
import { InviteDialogRemoveMode } from "./invite-dialog-remove-mode";
import { InviteDialogSentMode } from "./invite-dialog-sent-mode";

export interface InviteDialogProps {
  isOpen: boolean;
  isOwner?: boolean;
  members?: User[];
  onMemberRemoved?: (member: User) => void;
  onMemberInvited?: () => void;
  onClose: () => void;
}

export type InviteDialogMode = "default" | "remove" | "sent";

export interface State {
  mode: InviteDialogMode;
  email: string;
  removedUser: User | null;
}

export type Action =
  | { type: "SET_MODE"; mode: InviteDialogMode }
  | { type: "SET_EMAIL"; email: string }
  | { type: "SET_REMOVED_USER"; removedUser: User | null };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_MODE":
      return { ...state, mode: action.mode };
    case "SET_EMAIL":
      return { ...state, email: action.email };
    case "SET_REMOVED_USER":
      return { ...state, removedUser: action.removedUser };
    default:
      return state;
  }
};

const initialState: State = {
  mode: "default",
  email: "",
  removedUser: null,
};

export const InviteDialog: FC<InviteDialogProps> = ({
  isOpen,
  isOwner,
  members,
  onMemberRemoved,
  onMemberInvited,
  onClose,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useUser();

  return (
    <BaseDialog isOpen={isOpen} onClose={onClose} className="flex flex-col">
      <div>
        {state.mode === "default" && (
          <div className="flex items-center">
            <div className="flex-1">
              <span className="text-lg font-semibold text-slate-900">
                Invite contributors
              </span>
            </div>
          </div>
        )}
        {state.mode === "default" && user && (
          <InviteDialogDefaultMode
            state={state}
            isOwner={isOwner}
            membersToBeDisplayed={members}
            user={user}
            dispatch={dispatch}
            onMemberRemoved={onMemberRemoved}
            onMemberInvited={onMemberInvited}
          />
        )}
        {state.mode === "remove" && user && state.removedUser && (
          <InviteDialogRemoveMode state={state} dispatch={dispatch} />
        )}
        {state.mode === "sent" && user && (
          <InviteDialogSentMode state={state} dispatch={dispatch} />
        )}
        {!user && <LoadingScreen />}
      </div>
    </BaseDialog>
  );
};
